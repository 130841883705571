<template>
  <b-form-group class="pdf-schema-form-bank-information-group">
    <validation-observer ref="observer" v-slot="{ invalid }" />

    <!-- Bank Name ------------------------------------------------------------------------------->
    <b-row
      v-if="displayField('bank_name')"
      :tabindex="schemaTabIndex(fieldIds.bank_name)"
      class="sortable-field-container"
    >
      <b-col xs="12">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.bank_name)"
          name="Bank Name"
        >
          <b-form-input
            v-model="formData[fieldIds.bank_name]"
            placeholder="Bank Name"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('bank_name')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.bank_name)"
          />
          <p v-if="errors('Bank Name')" class="invalid">
            {{ errors('Bank Name') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Bank Account Holder ---------------------------------------------------------------------->
    <pdf-schema-form-person-group
      v-if="accountHolderPersonFields"
      :fields="accountHolderPersonFields"
      :contextual-jurisdiction="contextualJurisdiction"
      :use-hint="false"
      :show-contact-dropdown="false"
    />

    <!-- Bank Account Holder Address -------------------------------------------------------------->
    <pdf-schema-form-address-group
      v-if="accountHolderAddressFields"
      :fields="accountHolderAddressFields"
      :contextual-jurisdiction="contextualJurisdiction"
      :use-hint="false"
      :show-title="false"
      @enterPressed="$emit('enterPressed', $event)"
    />

    <!-- Bank Address ----------------------------------------------------------------------------->
    <pdf-schema-form-address-group
      v-if="bankAddressFields"
      :fields="bankAddressFields"
      :contextual-jurisdiction="contextualJurisdiction"
      :use-hint="false"
      :show-title="false"
      @enterPressed="$emit('enterPressed', $event)"
    />
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PdfSchemaFormBankInformationGroup',
  components: {
    PdfSchemaFormAddressGroup: () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormAddressGroup'),
    PdfSchemaFormPersonGroup: () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormPersonGroup'),
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    useHint: {
      type: Boolean,
      default: false,
    },
    contextualJurisdiction: null,
  },
  data() {
    return {
      fieldIds: {},
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'mapFieldIdsToGroupedFieldsParts',
      'isDisabledField',
      'validationRulesForFieldId',
      'validationState',
      'resourceFields',
      'schemaTabIndex',
    ]),
    initialField() {
      return this.fields[0]
    },
    title() {
      return  this.initialField.title || this.initialField.original_field_label
    },
    accountHolderPersonFields() {
      return this.fields.filter(field => field.data.parts[0]?.includes('bank_account_holder.'))
    },
    accountHolderAddressFields() {
      return this.fields.filter(field => field.data.parts[0]?.includes('bank_account_holder_address'))
    },
    bankAddressFields() {
      return this.fields.filter(field => field.data.parts[0]?.includes('bank_address'))
    },
  },
  beforeMount() {
    this.fieldIds = this.mapFieldIdsToGroupedFieldsParts(this.fields)
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentFieldById',
    ]),
    displayField(fieldName) {
      return Object.keys(this.fieldIds).includes(fieldName)
    },
    isDisabledFieldComponent(fieldName) {
      return this.isDisabledField(this.fields.find(f => f.id === this.fieldIds[fieldName]))
    },
    errors(fieldName) {
      return this.$refs.observer?.$_veeObserver?.errors && this.$refs.observer.$_veeObserver.errors[fieldName] ?
        this.$refs.observer.$_veeObserver.errors[fieldName].filter(error => !error.includes('required'))[0] :
        null
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },
  },
}
</script>

<style>
.pdf-schema-form-bank-information-group {
  margin-bottom: 0;
}
.invalid {
  font-size: 80%;
  color: #dc3545;
  margin-bottom: 0;
}
</style>
